import { createMuiTheme } from '@material-ui/core/styles';

const frontTheme = createMuiTheme({
  // Using the mixed font of both Montserrat and Noto Sane JP
  // for corresponding glyphs
  typography: {
    fontFamily: [
      'Noto Sans JP',
      '-apple-system',
      'BlinkMacSystemFont',
      'Roboto',
      'Segoe UI',
      'Helvetica Neue',
      'Hiragino Kaku Gothic ProN',
      'Meiryo',
      'sans-serif',
    ].join(','),
  },
  palette: {
    primary: {
      main: '#000665',
      contrastText: '#FFF',
      fill: '#FFF',
    },
    secondary: {
      main: '#2AE61E',
      contrastText: '#FFF',
    },
    error: {
      main: '#D53612',
    },
  },
  // https://stackoverflow.com/questions/54901465/material-ui-changing-default-color/56759028#56759028
  // https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/Button/Button.js
  // Not super ideal, setting the default button from grey to white
  overrides: {
    MuiButton: {
      contained: {
        color: '#000665',
        backgroundColor: '#FFF',
        '&:hover': {
          backgroundColor: '#FFF',
        },
      },
    },
  },
});

export default frontTheme;
